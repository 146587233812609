import React, { useEffect, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: ${({ isBuyButton }) => (isBuyButton ? "150px" : "100px")};
  cursor: pointer;

  transition: transform 0.1s ease-out;

  :active {
    transform: scale(0.95);
  }
  box-shadow: none;
  margin: ${({ isBuyButton }) =>
    isBuyButton
      ? "0"
      : "auto"}; /* Center horizontally if it's not a Buy button */
  :active {
    box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary-text);
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  @keyframes slideUpFadeInScale {
    0% {
      opacity: 0;
      transform: translateY(30px) scale(0.98);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;

  @media (min-width: 900px) {
    flex-direction: row;
  }
  @media (min-width: 1000px) {
    flex-direction: row;
  }
  animation: slideUpFadeInScale 1.5s forwards;
`;

export const StyledLogo = styled.img`
  @keyframes fadeInSlideDown {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  width: 20vw;
  max-width: 1000px;
  min-width: 50px;
  transition: width 0.5s, height 0.5s;
  animation: fadeInSlideDown 2s forwards;

  @media (max-width: 400px) {
    width: 40vw;
  }

  @media (min-width: 401px) and (max-width: 768px) {
    width: 30vw;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 25vw;
  }

  @media (min-width: 1025px) {
    width: 20vw;
  }
`;

export const StyledImg = styled.img`
  @keyframes spinInFadeIn {
    0% {
      opacity: 0;
      transform: rotate(-360deg);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  width: 200px;

  @media (min-width: 653px) {
    width: 200px;
  }

  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
  animation: spinInFadeIn 1.5s forwards;
`;

const NavBar = styled.nav`
  @keyframes fadeInSlideDown {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  position: fixed;
  top: 20px;
  right: 0;
  padding: 15px 25px; /* Increased padding */
  background-color: transparent;
  box-shadow: none;
  transition: width 0.5s, height 0.5s;
  animation: fadeInSlideDown 2s forwards;
  z-index: 999;

  .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        a {
          text-decoration: none;
          color: #4fd1c5;
          font-weight: bold;
          font-size: 18px; /* Default font size */
          line-height: 1.5;

          &:hover {
            color: #ffa500;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    position: static; /* Allows the NavBar to flow naturally in the document */
    padding: 10px 0; /* Reduced top padding to fit closely below the logo */
    .nav-container {
      justify-content: center;
      flex-direction: column; /* Stack items vertically */

      ul li {
        margin: 5px 0; /* Reduced margin for a tighter layout */
      }
    }
  }
`;

const MintButton = styled.a`
  display: inline-block;
  text-decoration: none;
  color: #4fd1c5;
  font-weight: bold;
  font-size: 16px; /* Default font size */
  border: 2px solid #4fd1c5;
  border-radius: 30px;
  padding: 8px 20px; /* Default padding */
  transition: all 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    font-size: 10px; /* Reduced font size for smaller screens */
    padding: 4px 10px; /* Slightly reduced padding for a more compact look */
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintPrice, setMintPrice] = useState(0);
  const [txLimit, setTxLimit] = useState(55);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // Run this effect when the smartContract instance is updated

  const claimNFTs = () => {
    let cost = mintPrice; // Use mintPrice state
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = (cost * mintAmount).toLocaleString("fullwide", {
      useGrouping: false,
    });
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log(cost * mintAmount);
    console.error(
      (cost * mintAmount).toLocaleString("fullwide", { useGrouping: false })
    );
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mintNFT()
      .send({
        gasLimit: totalGasLimit,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("transactionHash", (hash) => {
        // Transaction submitted
        console.log("Transaction Hash:", hash);
      })
      .on("confirmation", (confirmationNumber, receipt) => {
        // Transaction confirmed
        console.log("Confirmation Number:", confirmationNumber);
        console.log("Receipt:", receipt);
        setFeedback(`Success`);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      })
      .on("error", (error) => {
        // Transaction failed
        console.log("Error:", error);
        if (
          error.message.includes("Whitelisted address can only own one NFT")
        ) {
          setFeedback("You've already minted your free NFT.");
        } else {
          setFeedback("Sorry, something went wrong. Please try again later.");
        }
        setClaimingNft(false);
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const [isClicked, setIsClicked] = useState(false);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <NavBar>
          <div className="nav-container">
            <ul>
              <li>
                <a href="https://www.dazeddoge.com/">Home</a>
              </li>
              <li>
                <MintButton href="https://mint.dazeddoge.com/">
                  Mint DazedDoge
                </MintButton>
              </li>
            </ul>
          </div>
        </NavBar>
        <s.Container flex={1} ai={"center"} fd={"column"}>
          <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        </s.Container>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}></s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundImage: `url("/config/images/divimg.png"), linear-gradient(135deg, #FFA500 0%, #FF7F50 50%, #FF4500 100%)`,
              backgroundOrigin: "border-box",
              backgroundClip: "padding-box, border-box",
              borderImageOutset: "15px",
              padding: 24,
              borderRadius: 24,
              border: "double 4px transparent",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              position: "relative",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 100,
                fontWeight: "bold",
                color: "var(--accent-text2)",
                textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
              }}
            ></s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 100,
                fontWeight: "",
                color: "var(--secondary-text)",
                textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
              }} //supply
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--secondary-text)",
                textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
              }}
            ></s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    color: "var(--secondary-text)",
                    textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
                  }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--secondary-text)",
                    textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
                  }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    color: "var(--secondary-text)",
                    textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
                    fontSize: "36px",
                  }} //1 doge cost x
                >
                  1 {CONFIG.SYMBOL} costs {(mintPrice / 1e18).toLocaleString()}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />

                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--secondary-text)",
                        textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
                        fontSize: "24px", //connect to pulsechain
                      }}
                    >
                      Connect to {CONFIG.NETWORK.NAME}chain
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());

                        // Listen for the 'disconnect' event
                        if (window.ethereum) {
                          window.ethereum.on("disconnect", (error) => {
                            // Handle the disconnect event
                            // For example, you can update your app's state to indicate disconnection
                            // setConnected(false);
                          });
                        }

                        getData();
                        setIsClicked(true); // Set isClicked to true when the button is clicked
                      }}
                      className={isClicked ? "clicked" : ""}
                    >
                      <img
                        src="config/images/Connect.png"
                        alt="Custom Button"
                      />
                    </StyledButton>

                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--secondary-text)",
                            textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        fontSize: "24px",
                        textAlign: "center",
                        color: "var(--secondary-text)",
                        textShadow: "4px 4px 8px rgba(0, 0, 0, .8)",
                      }} //click buy to mint your nft
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container
                      ai={"center"}
                      jc={"center"}
                      fd={"row"}
                    ></s.Container>

                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? (
                          <img
                            src="/config/images/busy.png"
                            alt="Busy Button"
                          />
                        ) : (
                          <img
                            src="/config/images/mint.png"
                            alt="mint Button"
                          />
                        )}
                      </StyledButton>
                    </s.Container>
                    <Suspense fallback={<div>Loading...</div>}></Suspense>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}></s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.SpacerSmall />
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
